<template>
    <section class="section-testimonials relative" :class="color" v-if="testimonials && testimonials.length">
        <LayoutContained :class="padding">
            <ContentHeading
                v-if="heading"
                :heading="heading"
                :headingColor="headingColor"
                :headingComponent="headingComponent"
                marginBottom="mb-6"
                :textCentered="textCentered"
            ></ContentHeading>
        </LayoutContained>
        <div class="swiper relative" :class="uid">
            <!-- Swiper Navigation-->
            <div class="pointer-events-none absolute hidden h-full w-full lg:block">
                <div class="relative z-10 mx-auto h-full py-12 sm:py-16">
                    <div class="container mx-auto flex h-full items-center px-6 md:px-8 lg:px-10">
                        <div
                            @click="prevSlide"
                            :class="{
                                'cursor-auto text-sky-800 opacity-40' : isBeginning,
                                'cursor-pointer text-sky-800': !isBeginning
                            }">
                            <IconChevronLeft class="pointer-events-auto scale-[2]"></IconChevronLeft>
                        </div>
                        <div class="flex-grow"></div>
                        <div
                            @click="nextSlide"
                            :class="{
                                'cursor-auto text-sky-800 opacity-40' : isEnd,
                                'cursor-pointer text-sky-800': !isEnd
                            }">
                            <IconChevronRight class="pointer-events-auto scale-[2] text-sky-800"></IconChevronRight>
                        </div>
                    </div>
                </div>
            </div>
            <div class="swiper-wrapper cursor-default">
                <div class="swiper-slide h-full" v-for="(testimonial, index) in testimonials" :key="'section-testimonials-testimonial-' + index">
                    <div class="p1">
                        <LayoutTestimonial
                            :testimonialImage="testimonial.testimonialImage"
                            :testimonialName="testimonial.testimonialName"
                            :testimonialContent="testimonial.testimonialContent"
                            :color="color"
                        ></LayoutTestimonial>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
const swiper = ref();
const uid = ref();
const isBeginning = ref(null);
const isEnd = ref(null);

const {
    id,
    padding,
    testimonials,
    color,
    heading,
    headingColor,
    headingComponent,
    textCentered,
} = defineProps({
    id: {
        required: false,
        type: String,
    },
    padding: {
        required: false,
        default: 'pt-12 sm:pt-14',
        type: String,
    },
    testimonials: {
        required: true,
        type: Array,
    },
    color: {
        required: false,
        type: String,
        default: null,
    },
    heading: {
        required: false,
        type: String,
        default: null,
    },
    headingColor: {
        required: false,
        type: String,
        default: 'text-gray-900',
    },
    headingComponent: {
        required: false,
        type: String,
        default: 'h1',
    },
    textCentered: {
        required: false,
        type: [Boolean, Number],
        default: false,
    },
});

function prevSlide () {
    if (!swiper.value) return;
    swiper.value.slidePrev();
}

function nextSlide () {
    if (!swiper.value) return;
    swiper.value.slideNext();
}

function updateState () {
    isBeginning.value = swiper.value.isBeginning;
    isEnd.value = swiper.value.isEnd;
}

async function init () {
    // Initialise swiper
    const swiperPlugin = usePluginSwiper();
    await swiperPlugin.init();

    const {$Swiper, $SwiperNavigation} = useNuxtApp();

    swiper.value = new $Swiper('.' + uid.value, {
        modules: [$SwiperNavigation],
        speed: 500,
        spaceBetween: 30,
        centeredSlides: true,
        autoplay: {
            delay: 2500,
            disableOnInteraction: true,
        },
    });
    swiper.value.on('activeIndexChange', updateState);
    updateState();
}

uid.value = id ? `swiper-id${id}` : `swiper-uid-${useId()}`;

onMounted(() => {
    if (!swiper.value) init();
});

</script>
